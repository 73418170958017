// src/components/NDAForm.js
import React, { useState, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Box,
  CircularProgress,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { UploadFile, Delete } from "@mui/icons-material";

const NDAForm = ({ onAnalysisComplete }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState({ type: null, content: null });

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setMessage({
        type: "error",
        content:
          "Invalid file type. Only .pdf, .doc, and .docx files are allowed.",
      });
    } else {
      setFile(acceptedFiles[0]);
      setMessage({ type: null, content: null });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxSize: 5 * 1024 * 1024, // 5MB
  });

  const handleRemoveFile = () => {
    setFile(null);
    setMessage({ type: null, content: null });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setMessage({
        type: "error",
        content: "Please upload a file.",
      });
      return;
    }

    setLoading(true);
    setMessage({ type: null, content: null });

    const formData = new FormData();
    formData.append("contract_file", file);

    try {
      const response = await axios.post(
        "/api/v1/nda",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 180000,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );

      onAnalysisComplete(response.data);
    } catch (error) {
      setMessage({
        type: "error",
        content: error.response?.data?.detail || "Error submitting NDA.",
      });
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <form onSubmit={handleSubmit}>
        <Box
          {...getRootProps()}
          sx={{
            border: "1px solid #e3e4e8",
            borderRadius: "4px",
            padding: "16px",
            textAlign: "center",
            cursor: "pointer",
            backgroundColor: isDragActive ? "#f0f2f5" : "#ffffff",
            "&:hover": {
              backgroundColor: "#f0f2f5",
            },
            mb: 1,
          }}
        >
          <input {...getInputProps()} />
          <UploadFile
            sx={{
              fontSize: 24,
              color: "#4a76a8",
              mb: 0.5,
            }}
          />
          <Typography
            sx={{
              fontSize: "var(--font-size-small)",
              color: "var(--vk-light-text)",
            }}
          >
            Drop file here or click (.pdf, .doc, .docx)
          </Typography>
        </Box>

        {file && (
          <List
            sx={{
              backgroundColor: "var(--vk-light-gray)",
              borderRadius: "4px",
              mb: 1,
            }}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={handleRemoveFile}
                  size="small"
                  sx={{ color: "var(--vk-light-blue)" }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              }
            >
              <ListItemText
                primary={file.name}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "var(--font-size-small)",
                  },
                }}
              />
            </ListItem>
          </List>
        )}

        {loading && uploadProgress > 0 && (
          <Box sx={{ width: "100%", mt: 1, mb: 1 }}>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              sx={{
                backgroundColor: "#e3e4e8",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "var(--vk-light-blue)",
                },
              }}
            />
          </Box>
        )}

        {message.content && (
          <Typography
            sx={{
              fontSize: "var(--font-size-small)",
              color: message.type === "error" ? "#e64646" : "#4bb34b",
              mb: 1,
              padding: "8px",
              backgroundColor: "var(--vk-light-gray)",
              borderRadius: "4px",
            }}
          >
            {message.content}
          </Typography>
        )}

        <Button
          type="submit"
          fullWidth
          disabled={loading}
          sx={{
            backgroundColor: "var(--vk-light-blue)",
            color: "var(--vk-white)",
            fontSize: "var(--font-size-small)",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "var(--vk-blue)",
            },
            "&:disabled": {
              backgroundColor: "#e3e4e8",
              color: "var(--vk-light-text)",
            },
          }}
        >
          {loading ? (
            <CircularProgress
              size={20}
              sx={{ color: "var(--vk-light-text)" }}
            />
          ) : (
            "Analyze"
          )}
        </Button>
      </form>
    </Box>
  );
};

export default NDAForm;
