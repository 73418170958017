import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NDAResponse = ({ response, onHighlight }) => {
  const renderRiskItem = (key, data = false) => (
    <Accordion
      key={key}
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
        backgroundColor: "var(--vk-light-gray)",
        mb: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "var(--vk-light-blue)" }} />}
        sx={{
          "&.Mui-expanded": {
            minHeight: 48,
          },
          backgroundColor: "var(--vk-dark-gray)", // Added background color
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{
              fontSize: "var(--font-size-small)",
              fontWeight: 500,
            }}
          >
            {data.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "var(--font-size-small)",
              color: data.answer ? "#4bb34b" : "#e64646",
            }}
          >
            {data.answer ? "Нет риска" : "Риск"}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            fontSize: "var(--font-size-small)",
            mb: 2,
          }}
        >
          {data.reasoning}
        </Typography>
        {data.clauses?.map((clause, index) => (
          <button
            key={index}
            onClick={() => onHighlight(clause.id)}
            className="clause-button"
          >
            <strong>Пункт {clause.number}</strong>
            <br />
            {clause.citation}
          </button>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  const renderSummary = (summary) => (
    <Accordion
      key="summary"
      defaultExpanded
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
        backgroundColor: "var(--vk-light-gray)",
        mb: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "var(--vk-light-blue)" }} />}
        sx={{
          "&.Mui-expanded": {
            minHeight: 48,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{
              fontSize: "var(--font-size-small)",
              fontWeight: 500,
            }}
          >
            Резюме
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            fontSize: "var(--font-size-small)",
            mb: 2,
            whiteSpace: "pre-line",
          }}
        >
          {summary}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box>
      {renderSummary(response.summary)}
      {Object.entries(response).map(([key, data], index) => {
        if (key !== "html_body" && key !== "summary") {
          return renderRiskItem(key, data, index === 0);
        }
        return null;
      })}
    </Box>
  );
};

export default NDAResponse;
