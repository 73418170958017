// src/components/ContractAnalysis.js
import React, { useRef, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import NDAForm from "./NDAForm";
import NDAResponse from "./NDAResponse";

function ContractAnalysis() {
  const contractRef = useRef(null);
  const [activeHighlight, setActiveHighlight] = useState(null);
  const [analysisResponse, setAnalysisResponse] = useState(null);

  const handleHighlight = (highlight) => {
    setActiveHighlight(highlight);
    if (highlight && contractRef.current) {
      const highlightElement = contractRef.current.querySelector(
        `.${highlight}`
      );

      if (highlightElement) {
        highlightElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const handleAnalysisComplete = (data) => {
    setAnalysisResponse(data);
  };

  const handleNewAnalysis = () => {
    setAnalysisResponse(null);
    setActiveHighlight(null);
  };

  useEffect(() => {
    if (contractRef.current && activeHighlight) {
      // Remove existing highlights
      contractRef.current
        .querySelectorAll('[class*="highlight-"][class*="active"]')
        .forEach((el) => el.classList.remove("active"));

      // Add new highlights
      const highlightElements = contractRef.current.querySelectorAll(
        `.${activeHighlight}`
      );
      highlightElements.forEach((element) => element.classList.add("active"));

      // Scroll to first highlight
      if (highlightElements.length > 0) {
        highlightElements[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [activeHighlight]);

  return (
    <div className="app">
      <div className="analysis-container">
        <h2>Анализ рисков</h2>
        {!analysisResponse ? (
          <NDAForm onAnalysisComplete={handleAnalysisComplete} />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Button
                onClick={handleNewAnalysis}
                sx={{
                  backgroundColor: "var(--vk-light-blue)",
                  color: "var(--vk-white)",
                  fontSize: "var(--font-size-small)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "var(--vk-blue)",
                  },
                }}
              >
                Новый анализ
              </Button>
            </Box>
            <NDAResponse
              response={analysisResponse}
              onHighlight={handleHighlight}
            />
          </>
        )}
      </div>
      <div className="contract-container">
        <h2>Текст договора</h2>
        {analysisResponse ? (
          <div
            ref={contractRef}
            className="contract-text"
            dangerouslySetInnerHTML={{ __html: analysisResponse.html_body }}
          />
        ) : (
          <div className="contract-text">
            <p>Загрузите документ для анализа</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContractAnalysis;
